import { createRouter, createWebHistory } from "vue-router"; 

import AOS from "aos";
import 'aos/dist/aos.css';

import Home from "@/components/landingPage.vue";
import webApplicatie from "@/components/webApplicatie.vue";
import appApplicatie from "@/components/appApplicatie.vue";
import apiKoppeling from "@/components/apiKoppeling.vue";
import onlinePlatform from "@/components/onlinePlatform.vue";
import portalDev from "@/components/portalDev.vue";
import overons from "@/components/overPage.vue";
import contactpage from "@/components/contactPage.vue"
import casePagina from "@/components/casePage.vue";
import bedanktPagina from "@/components/bedanktPagina.vue";
import SEOAchterhoek from "@/components/SEOAchterhoek.vue";
import privacyverklaring from "@/components/PrivacyverklaringSection.vue";


const routes = [
 
    {
        path: '/',
        name: 'landingPage',
        component: Home,
        meta: {
            title: 'Home Pagina',
            description: 'Welkom bij SwensSoftware. Wij maken maatwerk SwensSware',
            keywords: 'maatwerk, webapplicatie, software ontwikkeling, IT oplossing, Aalten, Achterhoek, Gelderland, App applicatie '
        }
    },
    {
        path: '/diensten/web-applicatie',
        name: 'WebApplicatie',
        component: webApplicatie,
        meta: {
            title: 'Web applicatie',
            description: 'Bij swenssoftware maken we jouw web applicatie. Jij zit aan de knoppen.',
            keywords: 'maatwerk, webapplicatie, software ontwikkeling, IT oplossing, Aalten, Achterhoek, Gelderland, web applicatie laten maken ' 
        }
    },
    {
        path: '/diensten//app-applicatie',
        name: 'AppApplicatie',
        component: appApplicatie,
        meta: {
            title: 'App applicatie',
            description: 'Bij swenssoftware maken we jouw App applicatie. Jij zit aan de knoppen.',
            keywords: 'maatwerk, webapplicatie, software ontwikkeling, IT oplossing, Aalten, Doetinchem, Arnhem, Achterhoek, Gelderland, app applicatie laten maken ' 
        }
    },
    {
        path: '/diensten//api-koppeling',
        name: 'ApiKoppeling',
        component: apiKoppeling,
        meta: {
            title: 'Api koppelingen',
            description: 'Bij swenssoftware maken we jouw api koppekingen die bij je onderneming hoort.',
            keywords: 'maatwerk, webapplicatie, software ontwikkeling, IT oplossing, Aalten, Achterhoek, Gelderland, Api koppeling laten maken ' 
        }
    },
    {
        path: '/diensten//online-platform',
        name: 'onlinePlatform',
        component: onlinePlatform,
        meta: {
            title: 'App applicatie',
            description: 'Bij swenssoftware maken we jouw online platform, denk dan aan ecommerce bijv.',
            keywords: 'maatwerk, webapplicatie, software ontwikkeling, IT oplossing, Aalten, Doetinchem, Arnhem, Achterhoek, Gelderland, Online platform laten maken. '  
        }
    },
    {
        path: '/diensten//online-portal',
        name: 'portalDevelopment',
        component: portalDev,
        meta: {
            title: 'portal applicatie',
            description: 'Bij swenssoftware maken we jouw portal. Kom je niet weg met een standaard pakket? Dan zijn wij de oplossing.',
            keywords: 'maatwerk, webapplicatie, software ontwikkeling, IT oplossing, Aalten, Doetinchem, Arnhem, Achterhoek, Gelderland,  ' 
        }
    },
    {
        path: '/overons',
        name: 'overons',
        component: overons,
        meta: {
            title: 'Over Swenssoftware',
            description: 'Zelfstandige jonge ondernemer die met liefde voor software voor zichzelf is begonnen. ',
            keywords: 'maatwerk, webapplicatie, software ontwikkeling, IT oplossing, Aalten, Doetinchem, Arnhem, Achterhoek, Gelderland, SwensSoftware ' 
        }
    },
   
    {
        path: '/contact',
        name: 'contactpage',
        component: contactpage,
        meta: {
            title: 'Contact met Swensoftware',
            description: 'Kom in contact met SwensSoftware. Kunnen we je helpen met een software oplossing op maat?',
            keywords: 'maatwerk, webapplicatie, software ontwikkeling, IT oplossing, Aalten, Doetinchem, Arnhem, Achterhoek, Gelderland, SwensSoftware, contact ' 
        }
    },
    {
        path: '/cases',
        name: 'casePagina',
        component: casePagina,
        meta: {
            title: 'Gemaakt door SwensSoftware',
            description: 'Benieuwd waar wij ons mee bezig hebben gehouden of bezig houden? Kijk dan even tussen onze projecten.',
            keywords: 'maatwerk, webapplicatie, software ontwikkeling, IT oplossing, Aalten, Doetinchem, Arnhem, Achterhoek, Gelderland, SwensSoftware ' 
        }
    },
    {
        path: '/bedankt',
        name: 'bedankt',
        component: bedanktPagina,
    },
    {
        path: '/werkgebied/achterhoek',
        name: 'IT-Achterhoek',
        component: SEOAchterhoek,
        meta: {
            title: 'IT Oplossing Achterhoek',
            description: 'Bij Swenssoftware maken we de IT oplossing voor je onderneming',
            keywords: 'maatwerk, webapplicatie, software ontwikkeling, IT oplossing, Aalten, Doetinchem, Arnhem, Achterhoek, Gelderland, SwensSoftware ' 
        }
    },

   {
        path: '/privacyverklaring',
        name: 'privacyverklaring',
        component: privacyverklaring,
    },
   
   
   
    


]

const router = createRouter({
    routes,
    history: createWebHistory(),
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
      },
})

router.beforeEach((to, from, next) => {
    AOS.init(); // Initialize AOS
    next();
  });


  // Stel dynamische meta tags in
router.afterEach((to) => {
    document.title = to.meta.title || 'Standaard Titel';
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', to.meta.description || '');
    }
  });

export default router
