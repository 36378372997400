<template>
    <div class="pt-32"></div>
 </template>
 
 <script setup>
    import { useHead } from '@vueuse/head'

    useHead({
    title: 'Contact',
    link: [
    { rel: 'canonical', href: 'https://swenssoftware.nl/contact' },
    ],
    })
</script>