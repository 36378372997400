<template>
    
  <div class=" w-screen h-full pb-10 bg-donkerColor" id="Contactsectie">
    <div class="p-10 grid lg:grid-cols-2 pt-32 container mx-auto">


    <div class="text-left">
        <h1 class="text-2xl text-white pb-5">Weet je al waar je naar opzoek bent?</h1>
        <p class=" text-white">Weet je al waar je naar opzoek bent? Of wil je vrijblijvend een offerte opvragen? of kom je er niet helemaal uit wat het best bij je onderneming past? Neem gerust contact op d.m.v het formulier in te vullen en ik zal z.s.m contact met je opnemen.</p>
      
        <ul class="text-white pt-5">
          <li><i class="fa-solid fa-envelope pr-2" ></i> <a href="mailto:SwensSoftware@gmail.com">SwensSoftware@gmail.com</a></li>
          <li><i class="fa-solid fa-phone pr-2"></i> <a href="tel:06-24539865">06-24 53 98 65</a></li>
          
        </ul>
    
    </div>

      <form action="https://api.web3forms.com/submit" method="POST" class="md:max-w-md md:mx-auto bg-ligteColor p-12 rounded-xl ">

        <input type="hidden" name="access_key" value="0155f5f3-6446-4b51-ab01-c5c7bd7eb573">

        <div class="relative z-0 w-full mb-5 group">
            <input type="email" name="email" id="Email" class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:donkerColor peer" placeholder=" " required />
            <label for="email" class="peer-focus:font-medium absolute text-md text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:donkerColorpeer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
        </div>
      
        <div class="grid md:grid-cols-2 md:gap-6">
          <div class="relative z-0 w-full mb-5 group">
              <input type="text" name="Voornaam" id="Voornaam" class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:donkerColor peer" placeholder=" " required />
              <label for="Voornaam" class="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:donkerColor peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Voornaam</label>
          </div>
          <div class="relative z-0 w-full mb-5 group">
              <input type="text" name="Achternaam" id="Achternaam" class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:donkerColor peer" placeholder=" " required />
              <label for="Achternaam" class="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:donkerColor peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Achternaam</label>
          </div>
        </div>
        <div class="grid md:grid-cols-2 md:gap-6">
          <div class="relative z-0 w-full mb-5 group">
              <input type="tel"  name="Telefoon nummer" id="Telefoon nummer" class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:donkerColor peer" placeholder=" " required />
              <label for="Telefoon nummer" class="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:donkerColor peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Telefoon nummer</label>
          </div>
          <div class="relative z-0 w-full mb-5 group">
              <input type="text" name="Bedrijfsnaam" id="Bedrijfsnaam" class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:donkerColor peer" placeholder=" " />
              <label for="Bedrijfsnaam" class="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:donkerColor peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Bedrijfsnaam</label>
          </div>
        </div>
        <div class="relative z-0 w-full mb-5 group">
            <input type="text" name="website-url" id="website-url" class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:donkerColor peer" placeholder=" " />
            <label for="website-url" class="peer-focus:font-medium absolute text-md text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:donkerColorpeer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Website url</label>
        </div>

        <div class="relative z-0 w-full mb-5 group">
              <textarea type="text" name="Omschrijving" id="Omschrijving" class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:donkerColor peer" placeholder=" " required />
              <label for="Omschrijving" class="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:donkerColor peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Omschrijving</label>
          </div>

        <!-- Custom Confirmation / Success Page -->
         <input type="hidden" name="redirect" value="http://swenssoftware.nl/bedankt"> 


        <button type="submit" class="text-ligteColor bg-donkerColor hover:bg-hoverColor transition-all duration-300 focus:ring-4 focus:outline-none focus:hoverColor font-medium rounded-lg text-md w-full sm:w-auto px-5 py-2.5 text-center ">Versturen</button>
      </form>

    </div>

    <div class="container mx-auto md:grid md:grid-cols-2 lg:grid-cols-4 mt-10 text-start h-full sm:place-items-start pl-5 sm:pl-0">
   
      <div class="grid grid-items-center pb-5 sm:pb-0">   
        <a href="/"><img src="../assets/SwensSoftware LOGO_C2.png" alt="Maatwerk software jouw IT oplossing" class="w-40"></a>
        
      </div>

      <div class="flex flex-col ">   
        <h1 class="text-[40px] text-white">Diensten</h1>
        <ul class="text-white">
          <li class="group"><router-link to="/diensten/web-applicatie">Web-Applicatie <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-hoverColor"></span></router-link></li>
          <li class="group"><router-link to="/diensten/app-applicatie">App-Applicatie <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-hoverColor"></span></router-link></li>
          <li class="group"><router-link to="/diensten/portal">Portal maken <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-hoverColor"></span></router-link></li>
          <li class="group"><router-link to="/diensten/api-koppeling">API Koppelingen <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-hoverColor"></span></router-link></li>
          <li class="group"><router-link to="/diensten/online">Online platform <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-hoverColor"></span></router-link></li>
        </ul>
      </div>
      <div class=" flex flex-col ">
        <h1 class="text-[40px] text-white">Navieer snel</h1>
        <ul class="text-white">
          <li class="group"><router-link to="/">Home <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-hoverColor"></span></router-link></li>
          <li class="group"><router-link to="/cases">Cases <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-hoverColor"></span></router-link></li>
          <li class="group"><router-link to="/contact">Contact <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-hoverColor"></span></router-link></li>
          <li class="group"><router-link to="/werkgebied/achterhoek">Werkgebied <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-hoverColor"></span></router-link></li>

          
        </ul>
        
        
      </div>
      <div class="flex flex-col">
        <h1 class="text-[40px] text-white">Contact</h1>
        <ul class="text-white">
          <li><router-link to="contact">Contact Pagina</router-link></li>
          <li><router-link to=""><a href="tel:0624539865"> (+31) 6 24 53 98 65</a></router-link></li>
          <li><router-link to=""><a href="mailto:swenssoftware@gmail.com">Swenssoftware@gamil.com</a></router-link></li>
          <li>De Hare</li>
          <li>7121 XK Aalten</li>
         
       
        </ul>
      </div>
      
    </div>
  </div>

</template>

<script setup>

</script>
