<template>
  <div>
    <headerSection />

  <transition name="fadeCoockies">
    <coockiebanner v-if="showBanner" @hideBanner="showBanner = false" />
  </transition>

  <router-view />
  <footerSection />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import headerSection from './components/headerSection.vue';
import footerSection from './components/footerSection.vue';
import coockiebanner from './components/coockieSection.vue';

const showBanner = ref(true); // Maak de variabele reactief

onMounted(() => {
  // Controleer of $posthog bestaat en gebruik het
  if (window.$posthog) {
    const hasOptedIn = window.$posthog.has_opted_in_capturing();
    const hasOptedOut = window.$posthog.has_opted_out_capturing();

    // Toon de banner alleen als er geen keuze is gemaakt
    showBanner.value = !(hasOptedIn || hasOptedOut);  // Zorg dat deze check gebeurt voordat we de banner verbergen
    console.log('Opted In:', hasOptedIn, 'Opted Out:', hasOptedOut);
  } else {
    console.error('PostHog is niet beschikbaar. Controleer de initialisatie.');
    showBanner.value = true; // Forceer het tonen van de banner als PostHog niet werkt
  }
});
</script>

<style scoped>
.fadeCoockies-enter-active,
.fadeCoockies-leave-active {
  transition: none;
}
.fadeCoockies-enter,
.fadeCoockies-leave-to {
  opacity: 0;
}
</style>
