<template>
    <div class="container mx-auto py-32">
    <p>
        SwensSoftware, gevestigd aan De Hare. <br>Is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.
    </p>
        <h3 class="text-xl text-donkerColor">Contactgegevens:</h3>
    <ul>
        <li>    
        https://swenssoftware.nl/
       
        </li>
        <li> De Hare 124</li>
        <li><a href='tel:06 24539865'>06 24539865</a></li>
    </ul>
  
    

<h3 class="text-xl text-donkerColor pt-5">Persoonsgegevens die wij verwerken</h3>
<p>
    SwensSoftware verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u deze zelf aan ons verstrekt.

Hieronder vindt u een overzicht van de persoonsgegevens die wij verwerken:
- Locatiegegevens
- Gegevens over uw activiteiten op onze website
</p>


<h3 class="text-xl text-donkerColor pt-5">Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h3>

<p>Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als u er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via swenssoftware@gmail.com, dan verwijderen wij deze informatie.
</p>

<h3 class="text-xl text-donkerColor pt-5">Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h3>

<p>
    Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken
SwensSoftware verwerkt uw persoonsgegevens voor de volgende doelen:
- U te informeren over wijzigingen van onze diensten en producten
- Om goederen en diensten bij u af te leveren
- SwensSoftware analyseert uw gedrag op de website om daarmee de website te verbeteren en het aanbod van producten en diensten af te stemmen op uw voorkeuren. 
- SwensSoftware verwerkt ook persoonsgegevens als wij hier wettelijk toe verplicht zijn, zoals gegevens die wij nodig hebben voor onze belastingaangifte. 

</p>
<h3 class="text-xl text-donkerColor pt-5">Geautomatiseerde besluitvorming</h3>

<p>SwensSoftware neemt #responsibility op basis van geautomatiseerde verwerkingen besluiten over zaken die (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die worden genomen door computerprogramma's of -systemen, zonder dat daar een mens (bijvoorbeeld een medewerker van SwensSoftware) tussen zit. SwensSoftware gebruikt de volgende computerprogramma's of -systemen:
</p>

<h3 class="text-xl text-donkerColor pt-5">Hoe lang we persoonsgegevens bewaren</h3>

<p>
    SwensSoftware bewaart uw persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor uw gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van persoonsgegevens:

</p>


<h3 class="text-xl text-donkerColor pt-5">Delen van persoonsgegevens met derden</h3>

<p>SwensSoftware verstrekt uitsluitend aan derden en alleen als dit nodig is voor de uitvoering van onze overeenkomst met u of om te voldoen aan een wettelijke verplichting.
</p>

<h3 class="text-xl text-donkerColor pt-5">Cookies, of vergelijkbare technieken, die wij gebruiken</h3>
<p>SwensSoftware gebruikt alleen technische en functionele cookies. En analytische cookies die geen inbreuk maken op uw privacy. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen op uw computer, tablet of smartphone. De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website en uw gebruiksgemak. Ze zorgen ervoor dat de website naar behoren werkt en onthouden bijvoorbeeld uw voorkeursinstellingen. Ook kunnen wij hiermee onze website optimaliseren. U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen.
</p>

<h3 class="text-xl text-donkerColor pt-5">Gegevens inzien, aanpassen of verwijderen </h3>
<p>U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heeft u het recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw persoonsgegevens door SwensSoftware en heeft u het recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen om de persoonsgegevens die wij van u beschikken in een computerbestand naar u of een ander, door u genoemde organisatie, te sturen.

U kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van uw persoonsgegevens of verzoek tot intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens sturen naar swenssoftware@gmail.com.

Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw privacy. We reageren zo snel mogelijk, maar binnen vier weken, op uw verzoek.

SwensSoftware wil u er tevens op wijzen dat u de mogelijkheid heeft om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons

</p>


<h3 class="text-xl text-donkerColor pt-5">Hoe wij persoonsgegevens beveiligen</h3>
<p>SwensSoftware neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via swenssoftware@gmail.com</p>
</div>
</template>
<script></script>
