

<template>
   <div class="">
      <div class="md:container md:mx-auto grid lg:grid-cols-2 pt-52 pb-10 gap-10 lg:h-[65dvh]">
      
         <img data-aos="fade-right" data-aos-duration="1200" src="../assets/UIenUX.png" alt="Maatwerk App applicatie" class="rounded-xl h-72 md:h-[60dvh] w-full">
      
         <div data-aos="fade-left" data-aos-duration="1200" class="px-5 lg:px-0 text-donkerColor ">
            <span class="text-orange-500 text-bold text-lg">Creating Smart App Solutions</span>
            <h2 class="md:py-5 py-3">De App applicatie die je zoekt.</h2>
            <p class="lg:w-[80%]">Apps zijn programma’s die speciaal zijn ontworpen voor gebruik op mobiele apparaten zoals smartphones en tablets. Waar websites en webapplicaties via een browser werken, bieden apps een directe en vaak snellere toegang tot functionaliteiten en informatie door middel van een aparte installatie. Apps kunnen volledig gepersonaliseerd worden, met functies die specifiek zijn afgestemd op jouw gebruikers en hun behoeften.</p>   
            <button  class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-500"><a class='text-textColor' href="#Contactsectie"> Contact  <i class="fa-solid fa-arrow-right"></i></a></button>
   
         </div>
           
      </div>


      <div class="container mx-auto grid lg:grid-cols-2 pt-20 md:pt-72 pb-10 gap-10 ">
         <div class=" grid place-content-center lg:w-[80%] px-5 lg:p-0 text-donkerColor">

            
            <h1 class="lg:w-[80%] mb-5">De verschillende typen apps</h1>

            <p>Apps komen in allerlei soorten en maten, elk met hun eigen unieke functies en mogelijkheden. Of je nu een native app wilt laten ontwikkelen voor een specifiek besturingssysteem, een hybride app die meerdere platforms ondersteunt, of een web-app die via de browser toegankelijk is – de mogelijkheden zijn eindeloos. SwensSoftware helpt je graag bij het ontwikkelen van op maat gemaakte apps die perfect aansluiten bij jouw wensen en die van jouw gebruikers.</p>  </div>

         <div class="grid lg:grid-cols-2 gap-5 grid-rows-2 justify-content-stretch pb-52">

            <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5  drop-shadow-lg" data-aos="fade-up" data-aos-duration="500">
            <div class="text-donkerColor">
                  
               <i class="fa-solid fa-mobile-screen-button lg:text-2xl bg-donkerColor text-ligteColor w-10 h-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
                  <h3 class="text-xl">Native apps</h3>
                  <p>Native apps worden speciaal ontwikkeld voor één platform, zoals iOS of Android, en bieden daardoor optimale prestaties en toegang tot apparaatfuncties.</p>
            </div>

            </div>


            <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5  drop-shadow-lg" data-aos="fade-up" data-aos-duration="500">
               <div class="text-donkerColor">
                  <i class="fa-solid fa-robot lg:text-2xl bg-donkerColor text-ligteColor w-10 h-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
                  <h3 class="text-xl">Web-apps</h3>
                  <p>Web-apps zijn toegankelijk via de browser en functioneren als een app, zonder dat je iets hoeft te installeren</p>
               </div>
            </div>
            

            <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5  drop-shadow-lg" data-aos="fade-up" data-aos-duration="500">
               <div class="text-donkerColor">
                  <i class="fa-solid fa-globe lg:text-2xl bg-donkerColor text-ligteColor w-10 h-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
                  <h3 class="text-xl">Hybride apps</h3>
                  <p>Hybride apps combineren web- en native technologieën, waardoor ze op meerdere platforms werken en sneller te ontwikkelen zijn.</p>
               </div>
            </div>
         
         </div>
      </div>


      <div class="w-[100vw] h-full relative overflow-hidden">
  <!-- Video als achtergrond -->
  <video class="absolute top-0 left-0 w-full h-full object-cover hidden md:block" autoplay loop muted>
    <source src="../assets/video/7021889_Internet_Iot_1920x1080.mp4" type="video/mp4" />
    Je browser ondersteunt geen video.
  </video>

  <img src="../assets/headerimg.jpeg" alt="Swenssoftware software maatwerk" class="absolute top-0 left-0 w-full h-full object-cover block md:hidden">
   <!-- Gradient overlay -->
   <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-tr from-donkerColor via-white to-ligteColor opacity-50"></div>

   <!-- Content (optioneel) -->
   <div class="z-10 relative text-donkerColor pt-12 ">
   
      <div class=" text-center">
         <h3 class="text-3xl">Voordelen van app-applicaties</h3>
      </div>

      <div class="md:container mx-auto grid lg:grid-cols-2 place-content-center  gap-10 p-10">
         <div class="bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 " data-aos="fade-up-right">
            <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-bolt bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Efficiëntie</h2>
            <p>Apps zijn speciaal ontwikkeld voor mobiele apparaten, waardoor ze optimaal gebruikmaken van hardware zoals camera’s, GPS en sensoren. Dit versnelt workflows en maakt complexe taken eenvoudiger.</p>
         </div>

         <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10  " data-aos="fade-up-left">
            <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-gear  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i> Schaalbaarheid</h2>
            <p>Apps kunnen eenvoudig worden uitgebreid met nieuwe functies naarmate je bedrijf groeit. Updates zijn snel uit te rollen via app stores, zodat je gebruikers altijd toegang hebben tot de nieuwste mogelijkheden.</p>
         
         </div>
         <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 " data-aos="fade-up-right">
            <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-fingerprint  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Toegankelijkheid</h2>
            <p>Apps zijn altijd binnen handbereik. Gebruikers kunnen eenvoudig toegang krijgen tot belangrijke functies en informatie, zelfs offline, dankzij slimme opslagmogelijkheden.</p>
         
         </div>
         <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 " data-aos="fade-up-left">
            <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-list-check  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i> Personalisatie</h2>
            <p>Met een app kun je de ervaring volledig aanpassen aan de gebruiker. Denk aan persoonlijke instellingen, pushmeldingen en relevante content op basis van gebruikersgedrag.

   </p>
         
         </div>
      </div>

      
   </div>
   
   </div>


   <div class="w-[100vw] lg:h-[100vh] h-full relative bg-donkerColor">
      
         <div class="w-screen md:absolute md:top-0">
            
               <div class="overflow-hidden pt-10 pb-16">
                  <div class="inline-block animate-scroll">
                     <img src="../assets/logo.png" alt="vueLogo" class='w-25 h-20 mx-20'>
                     <img src="../assets/Vite.svg" alt="ViteLogo" class='w-25 h-20 mx-20'>
                     <img src="../assets/tailwindcss.png" alt="TailwindLogo" class='w-25 h-20 mx-20'>
                     <img src="../assets/firebase.webp" alt="Firebaselogo" class='w-25 h-20  mx-20'>
                     <img src="../assets/GithubLogo.png" alt="GithubLogo" class='w-25 h-20 mx-20'>
                     <img src="../assets/nodeJS.png" alt="NodeJsLogo" class='w-25 h-20 mx-20'>
                     <img src="../assets/figmaAfbeelding.png" alt="NodeJsLogo"  class='w-25 h-20  mx-20'>
                     <img src="../assets/relumeAfbeelding.png" alt="NodeJsLogo"  class='w-25 h-20  mx-20'>
                     
                     <img src="../assets/logo.png" alt="vueLogo"  class='w-25 h-20 mx-20'>
                     <img src="../assets/Vite.svg" alt="ViteLogo"  class='w-25 h-20 mx-20'>
                     <img src="../assets/tailwindcss.png" alt="TailwindLogo"  class='w-25 h-20 mx-20'>
                     <img src="../assets/firebase.webp" alt="Firebaselogo"  class='w-25 h-20 mx-20'>
                     <img src="../assets/GithubLogo.png" alt="GithubLogo" class='w-25 h-20 mx-20'>
                     <img src="../assets/nodeJS.png" alt="NodeJsLogo"  class='w-25 h-20  mx-20'>
                     <img src="../assets/figmaAfbeelding.png" alt="NodeJsLogo"  class='w-25 h-20  mx-20'>
                     <img src="../assets/relumeAfbeelding.png" alt="NodeJsLogo"  class='w-25 h-20  mx-20'>
                  </div>
               </div>
         </div>
         <div class="pt-[8.2%] grid md:grid-cols-2  lg:container mx-auto relative">

               <div class="bg-hoverColor rounded-r-full relative xl:w-[80%] xl:h-[90%] mt-52 md:block hidden">
                  <img src="../assets/KijkrechtsSwen.png" alt="Contact SwensSoftware" class="  absolute bottom-0 xl:-left-10">
               </div>

               <div class="gap-5 text-white px-5 lg:px-0 md:pt-28">
                  <h1 class="pb-5">App laten maken?</h1>
                  <p class="lg:w-[80%]">Wil je een mobiele app laten ontwikkelen die perfect aansluit bij jouw bedrijfsprocessen en doelstellingen? Bij SwensSoftware ben je aan het juiste adres. Met onze ervaring en expertise in app-ontwikkeling creëren we een oplossing die jouw gebruikerservaring optimaliseert en jouw organisatie helpt groeien. Of het nu gaat om een native app, hybride app of Progressive Web App, wij zorgen ervoor dat jouw app soepel werkt en naadloos integreert met bestaande systemen. Benieuwd naar de mogelijkheden binnen jouw budget of de kosten van een app-ontwikkeling? Vul het contactformulier in en ontdek wat we voor jou kunnen betekenen!</p>
                  <button class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-300"><router-link class='text-textColor' to="/Contact">Neem contact op <i class="fa-solid fa-arrow-right"></i></router-link></button>
            
               </div>

                  
               
            </div>
      

   
   </div>

</div>
</template>


<style scoped>
@keyframes scrollanimatie {
      0% {
          transform: translateX(0%);
      }
      100% {
          transform: translateX(-100%);
      }
  }

  .animate-scroll {
          display: flex;
          white-space: nowrap;
          will-change: transform;
          animation: scrollanimatie 25s linear infinite;
  }

  .panel {
  will-change: transform; /* Pre-optimalisatie hint */
  transform: translateZ(0); /* Forceer hardwareversnelling */
}
</style>
