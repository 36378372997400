<template>
  <div class="bg-ligteColor p-5 md:container border-0 rounded-xl animate-fadeInDown absolute m-5 md:m-0 md:top-[20%] md:left-[40%] md:w-[500px] z-[999] shadow-black shadow-md">
    <h3 class="text-xl text-bold text-black">
      <i class="fa-solid fa-cookie-bite"></i> Cookiebeleid
    </h3>

    <p class="text-textColor">
      Wij maken gebruik van cookies om de website-ervaring te verbeteren met behulp van Posthog.
      Wij maken ook gebruik van Session replay, dit doen wij om de website te optimaliseren.
      <br />
      U kunt ervoor kiezen om cookies te accepteren of te weigeren. Voor meer informatie verwijzen we u naar de
      <router-link to="/privacybeleid">Privacy beleid</router-link>.
    </p>

    <div class="grid items-center">
      <button
        @click="acceptCoockie"
        class="border-hoverColor border-2 p-3 mt-4 rounded-xl hover:bg-hoverColor transition duration-300 text-black"
      >
        <i class="fa-solid fa-cookie-bite"></i> Accepteren
      </button>
      <button @click="weigerCoockie" class="block p-2 m-2">Weigeren</button>
    </div>
  </div>
</template>

<script setup>
import { defineEmits } from 'vue';

// Events declareren
const emit = defineEmits(['hideBanner']);

// Functie voor acceptatie van cookies
const acceptCoockie = () => {
  console.log("accept coockie");
  window.$posthog.opt_in_capturing(); // Zet cookie op true na klik
  emit("hideBanner"); // Communiceert met de hideBanner in de App.vue-component
};

// Functie voor weigeren van cookies
const weigerCoockie = () => {
  console.log("weiger coockie");
  window.$posthog.opt_out_capturing(); // Zet cookie op false na klik
  emit("hideBanner"); // Communiceert met de hideBanner in de App.vue-component
};
</script>
