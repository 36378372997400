
<template>

<div class="">
   <div class="container mx-auto grid lg:grid-cols-2 pt-52 pb-10 gap-10 lg:h-[65dvh]">
   
      <img data-aos="fade-right" data-aos-duration="1200" src="../assets/4827.jpg" alt="maatwerk software online platform maken" class="rounded-xl h-72 md:h-[60dvh] w-full">
   
      <div data-aos="fade-left" data-aos-duration="1200" class="px-5 lg:px-0 text-donkerColor">
         <span class="text-orange-500 text-bold text-lg">Creating Smart Platform Solutions</span>
         <h2 class="md:py-5 py-3">Innovatieve Online Platformen</h2>
         <p class="lg:w-[80%]">
            Online platformen brengen vraag en aanbod op een slimme manier bij elkaar. Het kan daarbij gaan om arbeid of producten, maar bijvoorbeeld ook om geld, informatie, communicatie of entertainment.  Met online platformen kunnen bedrijven en ondernemers nieuwe diensten en businessmodellen ontwikkelen. O.a. Apple, Amazon, Google, Netflix, Uber, Airbnb en Microsoft zijn met innovatieve platformen en nieuwe businessmodellen zeer succesvol gebleken. SwensSoftware helpt je met het ontwikkelen van een online platform.
         </p>
         <button  class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-500"><a class='text-textColor' href="#Contactsectie"> Contact  <i class="fa-solid fa-arrow-right"></i></a></button>
   
      </div>
   </div>


   <div class="md:container md:mx-auto grid md:grid-cols-2 pt-20 md:pt-72 pb-10 gap-10 ">
      <div class=" grid place-content-center lg:w-[80%] px-5 lg:px-0 text-donkerColor">

         
         <h1 class="lg:w-[80%] mb-5">Soorten online platformen</h1>
         <p>Er zijn verschillende soorten online platformen, elk met specifieke doelen en functionaliteiten. Laat je inspireren door deze voorbeelden:</p>
      </div>

      <div class="grid lg:grid-cols-2 gap-5 grid-rows-2 justify-content-stretch pb-52">
         <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5 drop-shadow-xl shadow-donkerColor" data-aos="fade-up"  data-aos-duration="500">
           <div class="text-black">
               
               <i class="fa-solid fa-window-maximize lg:text-2xl bg-donkerColor text-ligteColor h-10 w-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h3 class="text-xl">E-commerce platformen</h3>
               <p>Websites zoals Amazon, Bol.com en eBay, waar gebruikers producten en diensten kunnen kopen en verkopen.</p>
           </div>

         </div>


         <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5 drop-shadow-xl shadow-donkerColor"  data-aos="fade-up"  data-aos-duration="500">
            <div class="text-black">
               <i class="fa-solid fa-earth-europe lg:text-2xl bg-donkerColor text-ligteColor h-10 w-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h3 class="text-xl leading-8">Content-sharing platformen</h3>
               <p>Platformen zoals YouTube, Vimeo en SoundCloud waar gebruikers video’s, audio en andere media kunnen uploaden, delen en consumeren.</p>
            </div>
         </div>


         <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5 drop-shadow-xl shadow-donkerColor" data-aos="fade-up" data-aos-duration="500">
            <div class="text-black">
               <i class="fa-solid fa-users-viewfinder lg:text-2xl bg-donkerColor text-ligteColor h-10 w-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h3 class="text-xl leading-8">Content-sharing platformen</h3>
               <p>Een online portaal, zoals een klantenportaal, dealerportaal, B2B-portaal of selfserviceportaal, geeft je via een browser beveiligde toegang tot data bij anderen.</p>
            </div>
         </div>
       
      </div>
   </div>


   <div class="w-[100vw] lg:h-[100vh] h-full relative overflow-hidden">
 <!-- Video als achtergrond -->
 <video class="absolute top-0 left-0 w-full h-full object-cover hidden md:block" autoplay loop muted>
    <source src="../assets/video/7021889_Internet_Iot_1920x1080.mp4" type="video/mp4" />
    Je browser ondersteunt geen video.
  </video>

  <img src="../assets/headerimg.jpeg" alt="Swenssoftware software maatwerk" class="absolute top-0 left-0 w-full h-full object-cover block md:hidden">

  <!-- Gradient overlay -->
  <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-tr from-donkerColor via-white to-ligteColor opacity-50"></div>

  <!-- Content (optioneel) -->
  <div class="z-10 relative text-donkerColor pt-12 ">
 
   <div class=" text-center">
      <h3 class="text-3xl">Voordelen van online platform.</h3>
   </div>

   <div class="md:container mx-auto grid lg:grid-cols-2 place-content-center  gap-10 p-10">
      <div class="bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 " data-aos="fade-up-right">
           <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-spa bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Digitaal ecosysteem</h2>
           <p>Een online platform vormt de kern van een krachtig ecosysteem van diensten en functionaliteiten dat digitale innovatie en samenwerking stimuleert. Denk bijvoorbeeld aan data-analyse, het koppelen van vraag en aanbod, het bundelen van functionaliteiten, of het samenbrengen van partners, dienstverleners en gebruikers in een centraal digitaal ecosysteem.</p>
      </div>

      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10  " data-aos="fade-up-left">
         <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-face-smile  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Superieure klantervaring</h2>
           <p>Door de centralisatie van diensten, functionaliteiten en data, wordt het makkelijker om klanten in elke fase van de customer journey te helpen. Ook versterken platformen de wisselwerking tussen vragers en aanbieders. Hoe groter en diverser het aanbod, hoe aantrekkelijker ze worden voor gebruikers en kopers, waardoor ook de aanzuigende werking toeneemt.</p>
        
      </div>
      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 " data-aos="fade-up-right">
         <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-database  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Krachtige datahub</h2>
           <p>Een online platform geeft je de mogelijkheid om data uit verschillende bronnen met elkaar te combineren. Een platform wordt zo een knooppunt van informatie, die sturing geeft aan je digitale en zakelijke strategie. Vanuit deze datahub kun je verschillende rapportages maken, die waardevolle inzichten opleveren waarmee je bijvoorbeeld je customer experience kunt vergroten.</p>
        
      </div>
      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 " data-aos="fade-up-left">
         <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-rocket  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i> Innovatiekracht</h2>
           <p>Innovatiekracht is belangrijk in een tijdperk waarin technologische ontwikkelingen elkaar snel opvolgen, klanten steeds hogere eisen stellen aan dienstverlening en markten extreem dynamisch zijn. Met een online platform ontwikkel je sneller en makkelijker disruptieve businessmodellen. Je vergroot ermee je innovatiekracht, omdat je processen op een radicaal andere manier kunt organiseren.</p>
        
      </div>
   </div>

   
  </div>
 
</div>


<div class="w-[100vw] lg:h-[100vh] h-full relative bg-donkerColor">
   
      <div class="w-screen md:absolute md:top-0">
           
            <div class="overflow-hidden pt-10 pb-16">
                <div class="inline-block animate-scroll">
                    <img src="../assets/logo.png" alt="maatwerk software online platform maken" class='w-25 h-20 mx-20'>
                    <img src="../assets/Vite.svg" alt="maatwerk software online platform maken" class='w-25 h-20 mx-20'>
                    <img src="../assets/tailwindcss.png" alt="maatwerk software online platform maken" class='w-25 h-20 mx-20'>
                    <img src="../assets/firebase.webp" alt="maatwerk software online platform maken" class='w-25 h-20  mx-20'>
                    <img src="../assets/GithubLogo.png" alt="maatwerk software online platform maken" class='w-25 h-20 mx-20'>
                    <img src="../assets/nodeJS.png" alt="maatwerk software online platform maken" class='w-25 h-20 mx-20'>
                    <img src="../assets/figmaAfbeelding.png" alt="maatwerk software online platform maken"  class='w-25 h-20  mx-20'>
                    <img src="../assets/relumeAfbeelding.png" alt="maatwerk software online platform maken"  class='w-25 h-20  mx-20'>
                    
                    <img src="../assets/logo.png" alt="maatwerk software online platform maken"  class='w-25 h-20 mx-20'>
                    <img src="../assets/Vite.svg" alt="maatwerk software online platform maken"  class='w-25 h-20 mx-20'>
                    <img src="../assets/tailwindcss.png" alt="maatwerk software online platform maken"  class='w-25 h-20 mx-20'>
                    <img src="../assets/firebase.webp" alt="maatwerk software online platform maken"  class='w-25 h-20 mx-20'>
                    <img src="../assets/GithubLogo.png" alt="maatwerk software online platform maken" class='w-25 h-20 mx-20'>
                    <img src="../assets/nodeJS.png" alt="maatwerk software online platform maken"  class='w-25 h-20  mx-20'>
                    <img src="../assets/figmaAfbeelding.png" alt="maatwerk software online platform maken"  class='w-25 h-20  mx-20'>
                    <img src="../assets/relumeAfbeelding.png" alt="maatwerk software online platform maken"  class='w-25 h-20  mx-20'>
                </div>
            </div>
        </div>
        <div class="pt-[8.2%] grid md:grid-cols-2  lg:container mx-auto relative">

            <div class="bg-hoverColor rounded-r-full relative xl:w-[80%] xl:h-[90%] mt-52 md:block hidden">
               <img src="../assets/KijkrechtsSwen.png" alt="Contact SwensSoftware" class="  absolute bottom-0 xl:-left-10">
            </div>

            
            <div class="gap-5 text-white px-5 lg:px-0 md:pt-28">
               <h1 class="pb-5">Een Online Platform Opzetten?</h1>
               <p class="w-[70%] md:w-[90%] py-3 z-50">Profiteer van het innovatieve vermogen en de vele voordelen van een online platform. SwensSoftware helpt je graag verder. We zijn gespecialiseerd in het bouwen van online platformen die volledig integreren met je bestaande IT-landschap.
                  Meer weten? Vul dan het contactformulier in voor een vrijblijvende kennismaking!</p>
               <button class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-300"><router-link class='text-textColor' to="/Contact">Neem contact op <i class="fa-solid fa-arrow-right"></i></router-link></button>
          
            </div>

            <div class="absolute -bottom-[9rem] right-0 sm:pl-10 sm:bg-hoverColor rounded-l-full md:hidden block z-10">
               <img src="../assets/LeunSwen.png" alt="Contact SwensSoftware" class="">
            </div>


         </div>
   

  
</div>

 </div>
</template>


<style scoped>
@keyframes scrollanimatie {
      0% {
          transform: translateX(0%);
      }
      100% {
          transform: translateX(-100%);
      }
  }

  .animate-scroll {
          display: flex;
          white-space: nowrap;
          will-change: transform;
          animation: scrollanimatie 25s linear infinite;
  }

  .panel {
  will-change: transform; /* Pre-optimalisatie hint */
  transform: translateZ(0); /* Forceer hardwareversnelling */
}
</style>
