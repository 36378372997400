// ./plugins/posthog.js
import posthog from "posthog-js";

export default {
  install(app) {
    // Initialiseer PostHog
    posthog.init('phc_b54eUQAphQnQVStZmLxgUMPcNB6CvNOSf9SES2aC0Qp', {
      api_host: 'https://eu.i.posthog.com',
    });

    // Maak het posthog-object beschikbaar als globale eigenschap
    app.config.globalProperties.$posthog = posthog;

    // (Optioneel) Maak het ook toegankelijk via window, als je dat nodig hebt
    window.$posthog = posthog;
  },
};