// ./plugin/posthog.js
import posthog from "posthog-js";

export default {
  install(app) {
    posthog.init('phc_b54eUQAphQnQVStZmLxgUMPcNB6CvNOSf9SES2aC0Qp', {
      api_host: 'https://eu.i.posthog.com',
    });

    app.config.globalProperties.$posthog = posthog;

    window.$posthog = posthog;

    console.log('PostHog Initialized');
  },
};
