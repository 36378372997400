<script setup>
import { useHead } from '@vueuse/head'

useHead({
title: 'Cases',
link: [
   { rel: 'canonical', href: 'https://swenssoftware.nl/cases' },
],
})
</script>

<template>
   <div class="text-donkerColor">
      <div class="container mx-auto grid lg:grid-cols-2 pt-52 pb-10 gap-10 lg:h-[65dvh] text-donkerColor">
   
         <img src="../assets/4827.jpg" alt="ICT oplossing maatwerk software" class="rounded-xl h-72 md:h-[40dvh]">

         <div class="">
            <span class="text-orange-500 text-bold text-lg">Creating Smart Solutions</span>
            <h2 class="md:py-5 py-3">Cases</h2>
            <p class="lg:w-[80%]">Benieuwd wat er komen gaat? Of wat wij gemaakt hebben? Het kan varieren tussen Web Applicaties, Portalen, of App's voor in de IOS of Android store.</p>
         </div>
      </div>
  



      <div class="container mx-auto w-screen grid-items-center grid lg:grid-cols-2 gap-20 my-20 p-5 lg:p-0 text-donkerColor" data-aos="fade-left">
         <div class=" place-content-center">
            <h2>Food sharing App</h2>
            <p>Momenteel ben ik bezig om een App te ontwikkelen voor de App store en de IOS store. Deze app dient voor mensen die graag in de keuken staan!</p>
            <span class="text-xl mt-5">Denk dan bijv aan.</span>
               <ul class="list-disc list-inside">
                  <li class="hover:list-decimal">Recepten opslaan</li>
                  <li class="hover:list-decimal">Boodschappen lijst maken dmv je ingredienten toe te voegen</li>
                  <li class="hover:list-decimal">Je recepten te delen met je vrienden/familie.</li>
                  <li class="hover:list-decimal">Al je recepten op een plek.</li>
                  <li class="hover:list-decimal">Altijd makkelijk en overal te nieuwe recepten toe te voegen en bewerken.</li>
               </ul>
            <p>Bekijk de website hier:  <a href="https://saveyourrecipes.com/">SaveYourRecipes</a> en maak een account aan om up-to-date te blijven over de ontwikkelingen.</p>
            
         </div>
         <img src="../assets/saveyourrecipes.jpg" alt="ICT oplossing maatwerk software" class="rounded-xl">
      </div>
   </div>
 



 </template>
 

